<template>
    <div>
        <h1>{{ header }}</h1>

        <div class="pad-20">
            <h3 class="dark-text margin-0">Create a New Product or Custom Service</h3>
            <!-- <p class="margin-0">Select product codes and assign your prices.</p> -->

            <div v-if="1" class="radius-5 pad-10 max800">
                <div class="flex bottom colx2">
                    <Input
                        class="dark-text block right-15"
                        type="text"
                        v-model="product_code"
                        label="Create an Abbreviation or Code*"
                    />
                    <Input
                        class="dark-text block right-15"
                        type="text"
                        v-model="product_name"
                        label="Enter Name or Description of Product*"
                    />
                    <button class="primary" @click="add_new_prod()">Save</button>
                </div>
            </div>

            <hr class="purple wide-40 top-20" />

            <h3 class="dark-text margin-0">Products and Custom Services</h3>
            <p class="margin-0">Select and assign a fee to saved product or custom service</p>
            <div class="pad-10">
                <div
                    v-for="(service_fee, key) in service_fees"
                    :key="service_fee.data_id"
                    class="flex center single-service max800"
                >
                    <div class="multiselect">
                        <label>Select a saved product or custom service</label>
                        <Multiselect
                            v-model="service_fee.selected"
                            :options="options"
                            :key="'select_' + service_fee.data_id"
                            track-by="id"
                            :custom-label="serviceCodeDesc"
                            @input="
                                () => {
                                    $forceUpdate();
                                }
                            "
                            @select="
                                (e) => {
                                    options = calibrateMenu(e, service_fee.selected, 'id', options);
                                }
                            "
                            :disabled="service_fee.disabled"
                            class="disabled"
                        >
                        </Multiselect>
                    </div>
                    <div class="fee">
                        <label>Enter Fee</label>
                        <CurrencyInput
                            @currency="debounceHandleCurrencyChange($event, service_fee)"
                            :id="'input_' + service_fee.data_id"
                            :name="'input_' + service_fee.data_id"
                            :key="'input_' + service_fee.data_id"
                            :initialValue="service_fee.cost"
                        />
                    </div>
                    <div class="delete-icon">
                        <span class="material-icons-outlined" @click="removeCpt(key)">delete</span>
                    </div>
                </div>
                <button class="add-service_btn no-bg text-button" @click="addCpt()">
                    <span class="material-icons purple">add_box</span> Assign a fee to a saved product or custom service
                </button>
            </div>
        </div>
        <!-- <div v-if="1" class="pad-20">
            <Input class="block bottom-15" type="text" v-model="product_code" label="New Product Code" />
            <Input class="block bottom-15" type="text" v-model="product_name" label="New Product Name" />
            <button class="add-service_btn no-bg text-button" @click="add_new_prod()">
                <span class="material-icons purple">add_box</span> Add New Products or Services.
            </button>
        </div> -->
    </div>
</template>

<script>
    import { debounce } from 'lodash';

    const api_root = 'service_fees';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import { generateId, calibrateMenu } from '@/util/genericUtilityFunctions';
    import { serviceCodes } from '@/util/apiRequests';

    export default {
        components: { CurrencyInput },
        name: 'CompanyProducts',
        data() {
            return {
                loading: 1,
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                service_fees: [],
                justModified: null,
                originalOptions: [],
                product_name: '',
                product_code: '',
                calibrateMenu
            };
        },
        computed: {
            header() {
                return this.headerText || this.pageMeta.title;
            }
        },
        methods: {
            async init() {
                this.level_id = this.$store.state.user.company_id;
                const res = await this.$api.get(`/${api_root}/list/company/${this.level_id}`);
                await this.getServiceCodeOptions();
                this.rows = res.data;
                this.service_fees = res.data
                    .filter((fee) => fee.level === 'company')
                    .filter((fee) => fee.code_type === 'product')
                    .map((element) => {
                        element.selected = {
                            service_code: element.service_code,
                            long_descrip: element.long_descrip,
                            id: element.service_code_id
                        };
                        element.disabled = true;
                        return element;
                    });
                this.options = this.options.filter((element) => {
                    if (this.service_fees.some((fee) => element.id == fee.selected?.id)) {
                        return false;
                    }
                    return true;
                });
                this.loading = 0;
            },
            async validate() {
                return 1;
            },
            async add_new_prod() {
                let body = {
                    item: {
                        product_name: this.product_name,
                        product_code: this.product_code
                    }
                };
                await this.$api.post(`/services/new-product`, body);
                await this.getServiceCodeOptions();
                this.product_name = '';
                this.product_code = '';
                this.$toasted.success('Record successfully created');
            },
            addCpt() {
                //This is necessary due to the CurrencyInput being a bit glitchy for correctly binding.
                this.service_fees.push({ data_id: generateId(), generated: true, selected: {}, cost: 0 });
            },
            serviceCodeDesc({ service_code, long_descrip }) {
                if (service_code && long_descrip) {
                    return `${service_code} ${long_descrip}`;
                }
                return 'Select an option';
            },
            handleCurrencyChange(newCurrencyTotal, item) {
                this.$set(item, 'cost', this.$getNumFromCurrency(newCurrencyTotal));
                this.update_setting(item);
            },
            debounceHandleCurrencyChange: debounce(function(newCurrencyTotal, item) {
                this.handleCurrencyChange(newCurrencyTotal, item);
            }, 400),
            async removeCpt(index) {
                let copy = this.service_fees[index];
                if (this.service_fees[index].generated == true) {
                    this.service_fees.splice(index, 1);
                    this.calibrateMenu(null, copy.selected, 'id', this.options);
                } else {
                    //Api Delete
                    try {
                        //Refer to update_setting comment on why we use id_after_generated_is_false
                        await this.$api.delete(
                            `/${api_root}/${
                                this.service_fees[index].id_after_generated_is_false
                                    ? this.service_fees[index].id_after_generated_is_false
                                    : this.service_fees[index].data_id
                            }`
                        );
                        this.service_fees.splice(index, 1);
                        this.options = this.calibrateMenu(null, copy.selected, 'id', this.options);
                    } catch (error) {
                        this.$toasted.error('Failed to delete Record. Please try again later.');
                    }
                }
            },
            async getServiceCodeOptions() {
                let result = await this.$api.get(serviceCodes.getProductCodes());
                let options = result.data?.map((element) => {
                    return { service_code: element.service_code, long_descrip: element.long_descrip, id: element.id };
                });
                this.options = options;
                this.originalOptions = this.options;
            },
            async update_setting(item) {
                try {
                    if (Object.keys(item.selected).length <= 0) {
                        return;
                    }
                    //new
                    if (item.generated == true) {
                        item.financial_class = 'self_pay';
                        item.insurance_payer_id = 0;
                        item.expected_reimbursement = 0;
                        let result = await this.$api.post(
                            `/${api_root}/${this.level}/${this.level_id}/${item.selected.id}`,
                            { item }
                        );
                        this.$toasted.success('Record successfully created');

                        this.$set(item, 'generated', false);
                        //Apparently you can't do the below code commented out, because it will mess with the CurrencyInput by preventing a user from typing.
                        // this.$set(item, 'data_id',  result.data.recId[0]);

                        this.$set(item, 'id_after_generated_is_false', result.data.recId[0]);
                        this.options = this.calibrateMenu(item, null, 'id', this.options);
                        this.$set(item, 'disabled', true);
                    }

                    //update
                    else {
                        let adata_id = 0;
                        if (item.id_after_generated_is_false) {
                            adata_id = item.id_after_generated_is_false;
                        } else {
                            adata_id = item.data_id;
                        }
                        item.financial_class = 'self_pay';
                        item.insurance_payer_id = 0;
                        item.expected_reimbursement = 0;
                        await this.$api.put(`/${api_root}/${adata_id}`, { item: { cost: item.cost } });

                        this.$toasted.success('Successfully updated Record.');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to update Record. Please try again later.');
                }
            }
            //This function removes options from being selected.
            // async calibrateMenu(newVal, oldVal) {
            //     if (oldVal !== null && oldVal) {
            //         //add option back into menu
            //         if (this.options.some((option) => option.id == oldVal.id) == false) {
            //             this.options.push(oldVal);
            //         }
            //     }
            //     //remove the new option
            //     this.options = this.options.filter((option) => {
            //         if (option?.id == newVal?.id) {
            //             return false;
            //         }
            //         return true;
            //     });
            // }
        },

        async created() {
            await this.init();
        }
    };
</script>
